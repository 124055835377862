import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';

import {
  Accordion, AccordionDetails, AccordionSummary, TablePagination,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import PrintIcon from '@material-ui/icons/Print';
import { PDFDownloadLink } from '@react-pdf/renderer';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useNavigate,  useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { Receipt } from '../../api/models/Payment';
import { getPaymentList } from '../../api/payments';
import {
  getCurrentPageFromParams, getPerPageFromParams, getUrlWithPagination,
} from '../../api/routes';
import { PAGINATION_PER_PAGE_OPTIONS } from '../../common/constants';
import { buildPaymentReceipt, sendPrintOrder } from '../../common/printingUtils';
import routes from '../../common/routes';
import { DateFilterType, DateFilterValueType } from '../../common/types';
import { formatDate } from '../../common/utils';
import { useTenant } from '../../contexts/useTenant';
import useDeviceDetect from '../../hooks/useDeviceDetect';
import MainLayout from '../layout/MainLayout';
import PaymentsReport from '../reports/PaymentsReport';
import BreadCrumbs from '../shared/BreadCrumbs';
import DateFilterField from '../shared/DateFilterField';
import AlignedColumn from '../shared/list_view_column_renderers/AlignedColumn';
import DateColumn from '../shared/list_view_column_renderers/DateColumn';
import NumericColumn from '../shared/list_view_column_renderers/NumericColumn';
import ListViewTable, { getTableRows, RenderersListType } from '../shared/ListViewTable';
import Spinner from '../shared/Spinner';


interface Props {
  // eslint-disable-next-line react/require-default-props
  inModal?: boolean;
  // eslint-disable-next-line no-unused-vars,react/require-default-props
  onPaymentSelect?: (payment?: Receipt) => void;
}

const StyledPDFLink = styled(PDFDownloadLink)`
  color: unset;
  text-decoration: none;
`;

const Wrapper = styled.div`
  color: unset;
  text-decoration: none;
`;

const MobileContent = styled.div`
  width: 100%;
`;

const ReceiptPreview = styled.div`
  display: flex;
  justify-content: center;
`;

const PaymentList: React.FC<Props> = ({ inModal, onPaymentSelect }) => {
  const [searchParams] = useSearchParams();
  const { currentTenantId } = useTenant();
  const isTouchDevice = useDeviceDetect();
  const [dateFilterValue, setDateFilterValue] = useState<DateFilterType>({
    filterMode: 'none',
    filterValue: null,
  });
  const [payments, setPayments] = useState<Receipt[]>([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(getCurrentPageFromParams(searchParams.get('page')));
  const [searchValue, setSearchValue] = useState(searchParams.get('search') ?? '');
  const [perPage, setPerPage] = useState(getPerPageFromParams(searchParams.get('per_page')));
  const isLoading = useRef<boolean>(false);
  const isFirstLoad = useRef<boolean>(true);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const tableHeaders = ['No. Recibo', 'Cliente', <AlignedColumn value="Monto Pagado" align="right" />, <AlignedColumn value="Monto Cuota" align="right" />, 'Cobrador', 'Fecha'];

  const fetch = useCallback(async () => {
    isLoading.current = true;
    const dateRange = {
      date_from: '',
      date_to: '',
    };
    switch (dateFilterValue.filterMode) {
      case 'single_date':
        dateRange.date_from = formatDate(dateFilterValue.filterValue as moment.Moment, 'api_date');
        dateRange.date_to = formatDate(dateFilterValue.filterValue as moment.Moment, 'api_date');
        break;
      case 'date_range':
        dateRange.date_from = formatDate((dateFilterValue.filterValue as DateFilterValueType)?.from as moment.Moment, 'api_date');
        dateRange.date_to = formatDate((dateFilterValue.filterValue as DateFilterValueType)?.to as moment.Moment, 'api_date');
        break;
    }
    const { data, error } = await getPaymentList(
      currentPage,
      perPage,
      searchValue === '' ? undefined : searchValue,
      currentTenantId,
      dateFilterValue.filterMode === 'none' ? undefined : dateRange);
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    } else if (data) {
      setPayments(data.records);
      setTotalRecords(data.pagination.total_records);
    } else {
      enqueueSnackbar('Sin datos para mostrar', { variant: 'info' });
    }
    isLoading.current = false;
    isFirstLoad.current = false;
  }, [currentPage, perPage, enqueueSnackbar, currentTenantId, searchValue, dateFilterValue.filterValue, dateFilterValue.filterMode]);

  useEffect(() => {
    if (!isFirstLoad.current) {
      fetch();
      if (!inModal) {
        navigate(getUrlWithPagination(routes.payments.list, currentPage, perPage, undefined,  searchValue), { replace: true });
      }
    }
  }, [currentPage, perPage, isFirstLoad, fetch, navigate, inModal, searchValue]);

  useEffect(() => {
    if (payments.length === 0 && !isLoading.current) {
      fetch();
    }
  }, [fetch, payments.length, isLoading]);

  const tableRows = useMemo(() => {
    const objectFields = ['id', 'receipt_number', 'customer_full_name', 'amount', 'installment_amount', 'salesman_name', 'created_at'] as Array<keyof Receipt>;
    const renderers: RenderersListType[] = [
      {
        field: 'amount',
        renderer: value => <NumericColumn value={value} format="money" />,
      },
      {
        field: 'installment_amount',
        renderer: value => <NumericColumn value={value} format="money" />,
      },
      {
        field: 'created_at',
        renderer: value => <DateColumn value={value} displayFormat="display_date_time"/>,
      },
    ];
    return getTableRows<Receipt>(payments, objectFields, renderers);
  }, [payments]);

  const handleRowSelect = (rowId: string) => {
    if (!onPaymentSelect) {
      return;
    }

    onPaymentSelect(payments.find(c => c?.id?.toString() === rowId.toString()));
  };

  const handleDateFilterChange = (value: DateFilterType) => {
    setDateFilterValue({ ...value });
  };

  const content = () => {
    if (isLoading.current) {
      return <Spinner/>;
    }

    const desktopContent = (
      <ListViewTable
        modelName="Pagos"
        formPageUrl=""
        tableHeaders={tableHeaders.map((th, idx) => ({
          id: idx.toString(),
          sortable: true,
          text: th,
        }))}
        tableRows={tableRows}
        currentPage={currentPage}
        onCurrentPageChange={newPage => setCurrentPage(newPage)}
        rowsPerPage={perPage}
        onRowsPerPageChange={newValue => setPerPage(newValue)}
        totalRecords={totalRecords}
        selectable={inModal}
        onRowSelect={rowId => handleRowSelect(rowId)}
        searchPlaceholder="No. Recibo o Cliente"
        onSearch={value => setSearchValue(value)}
        searchValue={searchValue}
        customActions={<>
          <DateFilterField
            filterMode={dateFilterValue.filterMode}
            filterValue={dateFilterValue.filterValue}
            onChange={value => handleDateFilterChange(value)}
          />
          <StyledPDFLink document={
            <PaymentsReport
              receipts={payments}
            />} fileName={`recibos_${formatDate(moment(), 'api_date')}.pdf`}>
            {({ loading }) =>
              loading ? (
                <Wrapper>
                Cargando...
                  <IconButton><HourglassEmptyIcon /></IconButton>
                </Wrapper>
              ) : (
                <Button
                  color="primary"
                  variant="outlined"
                  startIcon={<PrintIcon />}
                >
                Imprimir
                </Button>
              )
            }
          </StyledPDFLink>
        </>}
        noAddButton
        hasPagination
      />
    );

    const touchDeviceContent = (
      <>
        {payments.map(payment => {
          const receiptText = buildPaymentReceipt(payment);
          return (
            <Accordion key={`payment-${payment.id}-header`}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="panel1a-content"
              >
                <Typography>[{payment.receipt_number}]&nbsp;{payment.customer_full_name}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <MobileContent>
                  <ReceiptPreview>
                    <pre>{receiptText}</pre>
                  </ReceiptPreview>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => sendPrintOrder(receiptText)}
                    fullWidth
                  >
                    Imprimir
                  </Button>
                </MobileContent>
              </AccordionDetails>
            </Accordion>
          );
        })}
        <TablePagination
          rowsPerPageOptions={PAGINATION_PER_PAGE_OPTIONS}
          component="div"
          count={totalRecords}
          rowsPerPage={perPage}
          page={currentPage - 1}
          onPageChange={(_, newPage) => setCurrentPage(newPage +1)}
          onRowsPerPageChange={e => setPerPage(parseInt(e.target.value, 10))}
          labelRowsPerPage="Mostrar"
          labelDisplayedRows={({
            from, to, count,
          }) =>`${from}-${to} de ${count !== -1 ? count : `'más de' ${to}`}`}
        />
      </>
    );

    return (
      <>
        {
          isTouchDevice ? touchDeviceContent : desktopContent
        }
      </>
    );
  };

  return (
    <>
      { inModal ? (
        <>{content()}</>
      ) : (
        <MainLayout>
          <BreadCrumbs currentPage="Pagos" />
          {content()}
          {/*<PDFViewer style={{ width: '100%', height: '1500px' }}><PaymentsReport*/}
          {/*  receipts={payments}*/}
          {/*/></PDFViewer>*/}
        </MainLayout>
      )}
    </>
  );
};

export default PaymentList;
