import React from 'react';

import TextField from '@material-ui/core/TextField';
import {
  useFormContext, RegisterOptions, Controller, FieldError,
} from 'react-hook-form';
import styled from 'styled-components';

const InputWrapper = styled.div`
  padding: 0 0 1em 0;
`;

interface Props {
  name: string;
  label: string;
  // eslint-disable-next-line react/require-default-props
  placeholder?: string;
  // eslint-disable-next-line react/require-default-props
  validations?: RegisterOptions;
  // eslint-disable-next-line react/require-default-props
  required?: boolean;
}

const FormTextField: React.FC<Props> = ({
  name,
  label,
  placeholder,
  validations,
  required= false,
}) => {
  const { control } = useFormContext();

  const fieldValidations = validations ?? {};
  if (required) {
    fieldValidations.required = required;
  }

  const getErrorMessage = (error: FieldError) => {
    let message = error?.message;
    if (message === '' && required) {
      message = 'Este campo es requerido';
    }
    return message;
  };

  return (
    <>
      <Controller
        render={({ field, formState: { errors } }) => (
          <InputWrapper>
            <TextField
              label={label}
              placeholder={placeholder}
              error={Boolean(errors[name])}
              helperText={getErrorMessage(errors[name] as FieldError)}
              InputLabelProps={{ shrink: field.value || field.value === 0 }}
              fullWidth
              { ...field }
            />
          </InputWrapper>
        )}
        name={name}
        control={control}
        rules={fieldValidations}
      />
    </>
  );
};

export default FormTextField;
