import React, {
  createContext, useEffect, useState, useContext, PropsWithChildren, useCallback,
} from 'react';

import jwt_decode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

import routes from '../common/routes';
import {
  Consumer, Factory, Runnable,
} from '../common/types';

export interface Context {
  authToken: string;
  checkTokenValidity: Runnable;
  logout: Runnable;
  setToken: Consumer<string>;
  userId: string;
  validToken: boolean;
  validating: boolean;
}

const authTokenKey = 'auth_token';

const AuthContext = createContext<Context>({
  authToken: '',
  checkTokenValidity: () => null,
  logout: () => null,
  setToken: () => null,
  userId: '',
  validToken: false,
  validating: true,
});

const getDefaultAuthToken: Factory<string> = () => {
  const token = window.localStorage.getItem(authTokenKey);
  return token || '';
};

const defaultValidating: boolean = getDefaultAuthToken() !== '';

const useProvidedAuth: Factory<Context> = () => {
  const [authToken, setAuthToken] = useState(getDefaultAuthToken());
  const [validating, setValidating] = useState(defaultValidating);
  const [validToken, setTokenValidity] = useState(false);
  const [userId, setUserId] = useState('');
  const navigate = useNavigate();

  const setToken: Consumer<string> = token => {
    setValidating(true);
    if (token === '') {
      window.localStorage.removeItem(authTokenKey);
    } else {
      window.localStorage.setItem(authTokenKey, token);
    }
    setAuthToken(token);
  };

  const logout: Runnable = () => {
    window.localStorage.clear();
    window.location.reload();
    navigate(routes.login);
  };

  const isTokenValid = useCallback(() => {
    const decoded = jwt_decode<{
      exp: number;
      user_id: string;
    }>(authToken);
    const expiration = decoded.exp * 1000;
    const now = new Date();
    const current = now.getTime();
    // console.log('Validating... ', new Date(expiration), current);
    return expiration > current;
  }, [authToken]);

  useEffect(() => {
    if (authToken === '') {
      setTokenValidity(false);
    } else {
      const decoded = jwt_decode<{
        exp: number;
        user_id: string;
      }>(authToken);
      // const expiration = decoded.exp * 1000;
      // const now = new Date();
      // const current = now.getTime();
      const isValid = isTokenValid();
      setUserId(decoded.user_id);
      setTokenValidity(isValid);
      if (!isValid) {
        window.localStorage.removeItem(authTokenKey);
      }
    }
    setValidating(false);
  }, [authToken, isTokenValid]);

  const checkTokenValidity = () => {
    // const isValid = isTokenValid();
    // console.log('validating: ', isValid);
    // if (!isValid) {
    //   navigate(routes.login, { state: { from: location } });
    // }
  };

  return {
    authToken,
    checkTokenValidity: () => checkTokenValidity(),
    logout,
    setToken,
    userId,
    validToken,
    validating,
  };
};

const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const auth = useProvidedAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

const useAuth: Factory<Context> = () => useContext(AuthContext);

export { AuthContext, authTokenKey, AuthProvider, useAuth };
