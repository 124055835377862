const routes = {
  customer: {
    form: '/customers/:id',
    list: '/customers',
    paymentForm: '/customers/:id/payment-form',
  },
  dashboard: '/',
  loans: {
    dailyCustomerList: '/loans/daily-customer-list',
    loans: {
      form: '/loans/loans/:id',
      list: '/loans/loans',
    },
    quote: '/loans/quote',
  },
  login: '/login',
  payments: {
    form: '/payments/:id',
    list: '/payments',
  },

};

export default routes;
