import moment from 'moment';

import {
  API_DATE_FORMAT, DISPLAY_DATE_FORMAT, DISPLAY_DATE_TIME_FORMAT, DISPLAY_TIME_FORMAT,
} from './constants';
import { DateFormat } from './types';

const formatNumber: (
  // eslint-disable-next-line no-unused-vars
  format: 'money' | 'two-decimals',
  // eslint-disable-next-line no-unused-vars
  value?: number,
  // eslint-disable-next-line no-unused-vars
  prefix?: string
) => string | undefined = (format, value, prefix = 'C$') => {
  if (!value && value !== 0) {
    return;
  }

  const formattedValue = parseFloat(value.toString())
    .toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
  if (format === 'money') {
    return `${prefix} ${formattedValue}`;
  }

  return formattedValue.toString();
};

const formatDate: (
  // eslint-disable-next-line no-unused-vars
  date: moment.Moment,
  // eslint-disable-next-line no-unused-vars
  format: DateFormat,
) => string = (date, format) => {
  switch (format) {
    case 'display_date':
      return date.format(DISPLAY_DATE_FORMAT);
    case 'display_date_time':
      return date.format(DISPLAY_DATE_TIME_FORMAT);
    case 'api_date':
      return date.format(API_DATE_FORMAT);
    case 'time':
      return date.format(DISPLAY_TIME_FORMAT);
    default:
      return date.format(API_DATE_FORMAT);
  }
};

const formatAsCodeNumber = (value: string | number) => value.toString().padStart(5, '0');

// eslint-disable-next-line no-unused-vars
const safeNumber: (value: string | number, defaultValue?: number) => number = (value, defaultValue) => {
  const numericValue = parseFloat(value.toString());
  return isNaN(numericValue) ? defaultValue ?? 0 : numericValue;
};

export {
  formatNumber,
  formatAsCodeNumber,
  safeNumber,
  formatDate,
};
