import moment from 'moment';

import { BaseModel } from './BaeModel';
import { defaultSpouseValues, Spouse } from './Spouse';
import { DISPLAY_DATE_FORMAT } from '../../common/constants';

interface Customer extends BaseModel {
  prospect: boolean;
  first_name_1: string;
  first_name_2: string;
  lastname_1: string;
  lastname_2: string;
  id_card_type: number;
  id_card_number: string;
  nationality: string;
  gender: number;
  home_ownership_status: number;
  date_of_birth: string | moment.Moment;
  academic_status: string;
  home_address: string;
  city: string;
  municipality: string;
  phone_number: string;
  cellphone_number: string;
  average_credit_amount: string;
  tenant_id: number;
  name?: string;
  spouse?: Spouse;
}

interface CustomerInstallmentInfo {
  installment_id: number;
  customer_full_name: string;
  installment_status: string;
  installment_amount: number;
  overdue_installments_count: number;
  loan_start_date: string;
  loan_base_amount: number;
  loan_end_date: string;
  previous_total_due: number;
  last_payment_date: string;
  last_payment_amount: number;
  new_total_due: number;
  salesman_name: string;
  salesman_id: number;
}

interface CustomerForDailyList extends Customer {
  installments_count: number;
  display_name: string;
}

export const defaultCustomerValues = {
  academic_status: '',
  cellphone_number: '',
  city: 'Managua',
  date_of_birth: moment().format(DISPLAY_DATE_FORMAT),
  first_name_1: '',
  first_name_2: '',
  gender: 0,
  home_address: '',
  home_ownership_status: 0,
  id_card_number: '',
  id_card_type: 0,
  lastname_1: '',
  lastname_2: '',
  municipality: 'Managua',
  nationality: 'Nicaragüense',
  phone_number: '',
  spouse: defaultSpouseValues,
};

export type { Customer, CustomerForDailyList, CustomerInstallmentInfo };
