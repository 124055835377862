import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';

import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { CustomerForDailyList } from '../../../api/models/Customer';
import { getCustomerDailyList } from '../../../api/sales';
import routes from '../../../common/routes';
import MainLayout from '../../layout/MainLayout';

const Row = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 10px;
`;

const ActionButton = styled.div`
  display: flex;
  align-self: center;
  margin: 0 0 0 10px;
`;

type ListViewOptionsType = 'today' | 'upcoming';

const CustomerDailyList: React.FC = () => {
  // const isTouchDevice = useDeviceDetect();
  const navigate = useNavigate();
  const [view, setView] = useState<ListViewOptionsType>('today');
  const [customers, setCustomers] = useState<CustomerForDailyList[]>([]);
  const isLoading = useRef<boolean>(false);
  const isFirstLoad = useRef<boolean>(true);

  const fetch = useCallback(async () => {
    isLoading.current = true;
    const { data, error } = await getCustomerDailyList(undefined, undefined, undefined, undefined, { upcoming: view === 'upcoming' });
    if (error) {
      console.log('Error loading customer:', error);
    } else if (data) {
      setCustomers(data.records);
    } else {
      setCustomers([]);
    }
    isLoading.current = false;
    isFirstLoad.current = false;
  }, [view]);

  useEffect(() => {
    if (!isLoading.current && customers.length === 0 && isFirstLoad.current) {
      fetch();
    }
  }, [fetch, customers]);

  useEffect(() => {
    fetch();
  }, [view, fetch]);

  const handleChangeView = (event: React.MouseEvent<HTMLElement>, nextView: string) => {
    setView(nextView as ListViewOptionsType);
  };

  return (
    <MainLayout>
      <Paper>
        <Row>
          <ToggleButtonGroup
            value={view}
            exclusive
            onChange={handleChangeView}
          >
            <ToggleButton value="today" aria-label="list">
            Clientes del Dia
            </ToggleButton>
            <ToggleButton value="upcoming" aria-label="quilt">
            Cuotas Futuras
            </ToggleButton>
          </ToggleButtonGroup>
        </Row>
        <List>
          {customers.map((customer, idx) => (
            <>
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary={`${customer.display_name} (${customer.installments_count})`}
                  secondary={customer.home_address}
                />
                <ActionButton>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate(routes.customer.paymentForm.replace(':id', customer.id!.toString()))}
                  >
                    Abono
                  </Button>
                </ActionButton>
              </ListItem>
              {idx < customers.length -1 && <Divider variant="middle" component="li" />}
            </>
          ))}
        </List>
      </Paper>
    </MainLayout>
  );
};

export default CustomerDailyList;
