import React from 'react';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment from 'moment';
import styled from 'styled-components';

import { DISPLAY_DATE_FORMAT } from '../../common/constants';
import { Consumer } from '../../common/types';

const DateToolbar = styled.div`
  display: flex;
  align-items: flex-end;
  
  p {
    margin-bottom: 12.5px;
    margin-right: 1em;
  }
  
  .today-btn {
    margin-left: 1em;
    margin-bottom: 7px;
  }
  
  .MuiFormControl-marginNormal {
    width: 100%
  }
`;

interface Props {
  id: string;
  // eslint-disable-next-line react/require-default-props
  label?: string;
  value: moment.Moment | null;
  onChange: Consumer<moment.Moment | null>;
  // eslint-disable-next-line react/require-default-props
  format?: string;
  // eslint-disable-next-line react/require-default-props
  todayButton?: boolean;
}

const DateInputField: React.FC<Props> = ({
  label, onChange, value= moment(new Date()), format= DISPLAY_DATE_FORMAT, id, todayButton = false,
}) => {

  const handleDateChange = (date: MaterialUiPickersDate) => {
    onChange(date);
  };

  return (
    <DateToolbar>
      {label && <Typography>{label}</Typography>}
      <KeyboardDatePicker
        disableToolbar
        variant="inline"
        format={format}
        margin="normal"
        id={id}
        value={value}
        onChange={handleDateChange}
        KeyboardButtonProps={{
          'aria-label': `change ${label}`,
        }}
      />
      {todayButton && (
        <Button
          variant="contained"
          color="primary"
          className="today-btn"
          onClick={e => {
            e.preventDefault();
            onChange(moment(new Date()));
          }}
        >
        Hoy
        </Button>
      )}
    </DateToolbar>
  );
};

export default DateInputField;
