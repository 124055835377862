import { BaseModel } from './BaeModel';
import { Customer } from './Customer';
interface Spouse extends BaseModel {
  first_name_1: string;
  first_name_2: string;
  lastname_1: string;
  lastname_2: string;
  id_card_type: number;
  id_card_number: string;
  phone_number: string;
  cellphone_number: string;
  name?: string;
  customer?: Partial<Customer>;
}

export const defaultSpouseValues = {
  cellphone_number: '',
  first_name_1: '',
  first_name_2: '',
  id_card_number: '',
  id_card_type: 0,
  lastname_1: '',
  lastname_2: '',
  phone_number: '',
};

export type { Spouse };
