import React from 'react';

import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import { ClassNameMap } from '@material-ui/styles/withStyles';
import { Link } from 'react-router-dom';

import routes from '../../common/routes';

interface Props {
  classes: ClassNameMap;
}

const DrawerLinks: React.FC<Props> = ({ classes }) => (
  <>
    <Divider />
    <List>
      <Typography
        variant="overline"
        display="block"
        gutterBottom
        className={classes.drawerListGroupTitle}
      >
        Cobranza
      </Typography>
      <Link to={routes.loans.dailyCustomerList} className={classes.drawerListButton}>
        <ListItem button>
          <ListItemIcon><InboxIcon /></ListItemIcon>
          <ListItemText primary="Clientes del dia" />
        </ListItem>
      </Link>
      <Link to={routes.payments.list} className={classes.drawerListButton}>
        <ListItem button>
          <ListItemIcon><InboxIcon /></ListItemIcon>
          <ListItemText primary="Pagos" />
        </ListItem>
      </Link>
    </List>
    <Divider />
    <List>
      <Typography
        variant="overline"
        display="block"
        gutterBottom
        className={classes.drawerListGroupTitle}
      >
        Créditos
      </Typography>
      <Link to={routes.loans.loans.list} className={classes.drawerListButton}>
        <ListItem button>
          <ListItemIcon><InboxIcon /></ListItemIcon>
          <ListItemText primary="Contratos" />
        </ListItem>
      </Link>
      <Link to={routes.loans.quote} className={classes.drawerListButton}>
        <ListItem button>
          <ListItemIcon><InboxIcon /></ListItemIcon>
          <ListItemText primary="Cotización" />
        </ListItem>
      </Link>
      <Link to={routes.customer.list} className={classes.drawerListButton}>
        <ListItem button>
          <ListItemIcon><InboxIcon /></ListItemIcon>
          <ListItemText primary="Clientes" />
        </ListItem>
      </Link>
    </List>
    <Divider />
  </>
);

export default DrawerLinks;
