import React, { PropsWithChildren } from 'react';

import { useTheme } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {
  alpha, makeStyles, Theme, createStyles,
} from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MenuIcon from '@material-ui/icons/Menu';
import Store from '@material-ui/icons/Store';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import DrawerLinks from './DrawerLinks';
import DCLogo from '../../assets/images/logo/dcredito-logo.png';
import routes from '../../common/routes';
import { GenericObjectType } from '../../common/types';
import { useTenant } from '../../contexts/useTenant';

const LogoMap: GenericObjectType = {
  1: DCLogo,
};

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        duration: theme.transitions.duration.leavingScreen,
        easing: theme.transitions.easing.sharp,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        duration: theme.transitions.duration.enteringScreen,
        easing: theme.transitions.easing.easeOut,
      }),
      width: `calc(100% - ${drawerWidth}px)`,
    },
    appContent: {
      paddingTop: '1em',
    },
    content: {
      flexGrow: 1,
      marginLeft: -drawerWidth,
      overflow: 'hidden',
      padding: theme.spacing(2),
      transition: theme.transitions.create('margin', {
        duration: theme.transitions.duration.leavingScreen,
        easing: theme.transitions.easing.sharp,
      }),
    },
    contentShift: {
      marginLeft: 0,
      transition: theme.transitions.create('margin', {
        duration: theme.transitions.duration.enteringScreen,
        easing: theme.transitions.easing.easeOut,
      }),
    },
    drawer: {
      flexShrink: 0,
      width: drawerWidth,
    },
    drawerHeader: {
      alignItems: 'center',
      display: 'flex',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
    drawerListButton: {
      color: 'unset',
      textDecoration: 'none',
    },
    drawerListGroupTitle: {
      paddingLeft: theme.spacing(),
      paddingRight: theme.spacing(),
    },
    drawerPaper: {
      width: drawerWidth,
    },
    grow: {
      flexGrow: 1,
    },
    hide: {
      display: 'none',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
    inputRoot: {
      color: 'inherit',
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    root: {
      display: 'flex',
    },
    search: {
      '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      'backgroundColor': alpha(theme.palette.common.white, 0.15),
      'borderRadius': theme.shape.borderRadius,
      'marginLeft': 0,
      'marginRight': theme.spacing(2),
      'position': 'relative',
      'width': '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
      },
    },
    searchIcon: {
      alignItems: 'center',
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      padding: theme.spacing(0, 2),
      pointerEvents: 'none',
      position: 'absolute',
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    title: {
      display: 'block',
      // [theme.breakpoints.up('sm')]: {
      //   display: 'block',
      // },
    },
  }),
);

const MainLogoLink = styled(Link)`
  display: flex;
  align-content: center;
  align-items: center;
`;

const MainLogo = styled.img`
  width: 2.5em;
  margin-right: 1em;
`;

const MainLogoLabel = styled(Typography)`
  font-family: "Dancing Script",serif;
  font-size: 1.5em;
  font-weight: bold;
`;

const TenantLabel = styled(Typography)`
  font-family: "Dancing Script",serif;
  font-size: 1em;
  margin-left: 10px;
  //font-weight: bold;
`;

const ContentBG = styled.div<{ tenantId: number }>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${props => props.tenantId === 1 ? '#1a093109' : '#2c9cc209'};
  z-index: -1;
`;

const MainLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const classes = useStyles();
  const theme = useTheme();
  const {
    currentTenantId, tenants, setCurrentTenantId,
  } = useTenant();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      id={menuId}
      keepMounted
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {tenants.map(tenant => (
        <MenuItem
          onClick={() => {
            setCurrentTenantId(tenant.id ?? 1);
            handleMenuClose();
          }}
          key={`tenant-${tenant.id}`}
          selected={tenant.id === currentTenantId}
        >{tenant.name}</MenuItem>
      ))}
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{  horizontal: 'right', vertical: 'top' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <Link to={routes.loans.quote} className={classes.drawerListButton}>
          <p>Cotización</p>
        </Link>
      </MenuItem>
      <MenuItem>
        <Link to={routes.loans.dailyCustomerList} className={classes.drawerListButton}>
          <p>Clientes del dia</p>
        </Link>
      </MenuItem>
      <MenuItem>
        <Link to={routes.payments.list} className={classes.drawerListButton}>
          <p>Pagos</p>
        </Link>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: drawerOpen,
        })}>
        <Toolbar>
          <div className={classes.sectionDesktop}>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
            >
              <MenuIcon/>
            </IconButton>
          </div>
          <MainLogoLink to={routes.dashboard} className={classes.drawerListButton}>
            <MainLogo src={LogoMap[currentTenantId]} alt="logo"/>
            <MainLogoLabel className={classes.title} variant="h6" noWrap>
            D-CRÉDITO
            </MainLogoLabel>
          </MainLogoLink>
          {/*<div className={classes.search}>*/}
          {/*  <div className={classes.searchIcon}>*/}
          {/*    <SearchIcon/>*/}
          {/*  </div>*/}
          {/*  <InputBase*/}
          {/*    placeholder="Buscar…"*/}
          {/*    classes={{*/}
          {/*      input: classes.inputInput,*/}
          {/*      root: classes.inputRoot,*/}
          {/*    }}*/}
          {/*    inputProps={{ 'aria-label': 'search' }}*/}
          {/*    onChange={e => setQuery(e.target.value)}*/}
          {/*    value={query}*/}
          {/*    onBlur={performSearch}*/}
          {/*    onKeyUp={e => {*/}
          {/*      if (e.key === 'Enter') {*/}
          {/*        performSearch();*/}
          {/*      }*/}
          {/*    }}*/}
          {/*  />*/}
          {/*</div>*/}
          <div className={classes.grow}/>
          <div className={classes.sectionDesktop}>
            {/*<IconButton aria-label="show 4 new mails" color="inherit">*/}
            {/*  <Badge badgeContent={4} color="secondary">*/}
            {/*    <MailIcon/>*/}
            {/*  </Badge>*/}
            {/*</IconButton>*/}
            {/*<IconButton aria-label="show 17 new notifications" color="inherit">*/}
            {/*  <Badge badgeContent={17} color="secondary">*/}
            {/*    <NotificationsIcon/>*/}
            {/*  </Badge>*/}
            {/*</IconButton>*/}
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <Store/>
              <TenantLabel>{tenants.find(t => t.id === currentTenantId)?.name}</TenantLabel>
            </IconButton>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MenuIcon/>
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={drawerOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <DrawerLinks classes={classes} />
      </Drawer>
      {renderMobileMenu}
      {renderMenu}
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: drawerOpen,
        })}
      >
        <div className={classes.drawerHeader} />
        {children ?? <></>}
        <ContentBG tenantId={currentTenantId}/>
      </main>
    </div>
  );
};

export default MainLayout;
