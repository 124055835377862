import { BASE_URL } from './api';
import { RECORDS_PAGINATION } from '../common/constants';
import { GenericObjectType } from '../common/types';

const AUTH_ROUTES = {
  login: 'auth/login',
};

const TENANT_ROUTES = {
  tenant: {
    list: '/tenants',
  },
};

const LOAN_ROUTES = {
  customer: {
    daily_list: '/customers/daily_list',
    list: '/customers',
    oldest_unpaid_installment: '/customers/:id/oldest_unpaid_installment',
    single: '/customers/:id',
  },
  loan: {
    list: '/loans',
    single: '/loans/:id',
  },
  payment: {
    list: '/payments',
    single: '/payments/:id',
  },
};

// eslint-disable-next-line no-unused-vars
const getUrlWithPagination: (url: string, page?: number, perPage?: number, fields?: string, search?: string, extraParams?: GenericObjectType) => string = (
  url,
  page = RECORDS_PAGINATION.page,
  perPage = RECORDS_PAGINATION.perPage,
  fields,
  search,
  extraParams,
) => {
  const urlWithParams = new URL(`${BASE_URL}${url}`);
  urlWithParams.searchParams.append('page', page.toString());
  urlWithParams.searchParams.append('per_page', perPage.toString());
  if (fields) {
    urlWithParams.searchParams.append('fields', fields);
  }
  if (search) {
    urlWithParams.searchParams.append('search', search);
  }
  if (extraParams) {
    Object.keys(extraParams).map(k => urlWithParams.searchParams.append(k, extraParams[k]));
  }

  return `${url}${urlWithParams.search}`;
};

const getCurrentPageFromParams = (page: string | null) => {
  if (!page) {
    return RECORDS_PAGINATION.page;
  }
  return parseInt(page, 10);
};

const getPerPageFromParams = (perPage: string | null) => {
  if (!perPage) {
    return RECORDS_PAGINATION.perPage;
  }
  return parseInt(perPage, 10);
};

export {
  AUTH_ROUTES,
  LOAN_ROUTES,
  TENANT_ROUTES,
  getUrlWithPagination,
  getCurrentPageFromParams,
  getPerPageFromParams,
};
