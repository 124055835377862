import React from 'react';

import {
  Routes,
  Route,
} from 'react-router-dom';

import PrivateRoute from './PrivateRoute';
import routes from '../../common/routes';
import LoanForm from '../loans/loan/LoanForm';
import LoanList from '../loans/loan/LoanList';
import QuoteForm from '../loans/quotes/QuoteForm';
import Login from '../login/Login';
import NotFoundPage from '../not_found/NotFoundPage';
import PaymentList from '../payments/PaymentList';
import CustomerDailyList from '../sales/customer/CustomerDailyList';
import CustomerForm from '../sales/customer/CustomerForm';
import CustomerList from '../sales/customer/CustomerList';
import PaymentForm from '../sales/customer/PaymentForm';


const AppRoutes:React.FC = () => {

  return (
    <Routes>
      <Route path={routes.dashboard} element={<PrivateRoute><QuoteForm /></PrivateRoute>} />
      <Route path={routes.loans.quote} element={<PrivateRoute><QuoteForm /></PrivateRoute>} />
      <Route path={routes.loans.loans.list} element={<PrivateRoute><LoanList /></PrivateRoute>} />
      <Route path={routes.loans.loans.form} element={<PrivateRoute><LoanForm /></PrivateRoute>} />
      <Route path={routes.loans.dailyCustomerList} element={<PrivateRoute><CustomerDailyList /></PrivateRoute>} />
      <Route path={routes.customer.list} element={<PrivateRoute><CustomerList /></PrivateRoute>} />
      <Route path={routes.customer.form} element={<PrivateRoute><CustomerForm /></PrivateRoute>} />
      <Route path={routes.customer.paymentForm} element={<PrivateRoute><PaymentForm /></PrivateRoute>} />
      <Route path={routes.payments.list} element={<PrivateRoute><PaymentList /></PrivateRoute>} />
      <Route path={routes.login} element={<Login />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default AppRoutes;
